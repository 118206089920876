<template>
    <div class="card">

        <b-form @submit.stop.prevent="savePassword">
            <b-form-group id="oldPassword" label="Vecchia password" label-for="oldPassword" description="">
                <b-form-input v-model="item.oldPassword"
                              placeholder=""
                              type="password"
                              :state="validateState('oldPassword')"
                              aria-describedby="oldPassword-live-feedback"></b-form-input>
                <b-form-invalid-feedback
                    id="oldPassword-live-feedback"
                >Questo campo è obbligatorio.
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="newPassword" label="Nuova password" label-for="newPassword" description="">
                <b-form-input v-model="item.newPassword"
                              placeholder=""
                              type="password"
                              :state="validateState('newPassword')"
                              aria-describedby="newPassword-live-feedback"></b-form-input>
                <b-form-invalid-feedback
                    id="newPassword-live-feedback"
                >Questo campo è obbligatorio.
                </b-form-invalid-feedback>
            </b-form-group>
        </b-form>

        <div class="action-bar row">
            <div class="col-sm-8">
                <button class="btn btn-success " @click="saveProgram" :disabled="this.$store.state.loading">
                    <b-icon icon="file-earmark-check"/>
                    Salva
                </button>
                <b-button variant="link" @click="closeEditor" :disabled="this.$store.state.loading">
                    <b-icon icon="x"/>
                    Chiudi
                </b-button>
            </div>
            <div class="col-sm-4 text-right">

            </div>
        </div>
        <div>
            <br>
            <b-alert variant="success" :show="message != ''" fade>
                <b-icon icon="hand-thumbs-up"/>
                {{ message }}
            </b-alert>
            <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
        </div>


    </div>
</template>

<script>
import AuthDataService from "../auth/AuthDataService";
import {required} from 'vuelidate/lib/validators'

export default {
    name: "program-create",
    data() {
        return {
            item: {
                oldPassword: '',
                newPassword: '',
            },
            submitted: false,
            message: '',
            errorMsg: '',
        };
    },
    validations: {
        item: {
            oldPassword: {
                required,
            },
            newPassword: {
                required,
            },
        }
    },
    methods: {
        validateState(name) {
            const {$dirty, $error} = this.$v.item[name];
            return $dirty ? !$error : null;
        },
        saveProgram() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                AuthDataService.changePassword(this.item)
                    .then(response => {
                        if (response.status == 200) {
                            this.message = 'Password modificata con sucecsso';
                            this.errorMsg = '';
                            this.submitted = true;
                            this.$emit('password-changed');
                        } else {
                            this.errorMsg = 'Vecchia password errata';
                            this.item.oldPassword = null;
                        }
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },

        closeEditor() {
            this.$emit('closed-editor');
        },
    }
};
</script>

<style>

</style>
