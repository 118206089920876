import http from "../../helpers/http-common";

class ProductLicenseTemplateDataService {
    getAll() {
        return http.get("/product_license_templates");
    }

    getAllBySchool(schoolId) {
        return http.get(`/product_license_templates/by_school/${schoolId}`);
    }

    get(id) {
        return http.get(`/product_license_templates/${id}`);
    }

    create(data) {
        return http.post("/product_license_templates", data);
    }

    update(id, data) {
        return http.put(`/product_license_templates/${id}`, data);
    }

    delete(id) {
        return http.delete(`/product_license_templates/${id}`);
    }

    deleteAll() {
        return http.delete('/product_license_templates');
    }

    findByName(name) {
        return http.get(`/product_license_templates?name=${name}`);
    }
}

export default new ProductLicenseTemplateDataService();
