import http from "../../helpers/http-common";

class ProfileDataService {

    get(id) {
        return http.get(`/profiles/${id}`);
    }

    getByEmail(email) {
        return http.get(`/profiles/by-email/${email}`);
    }

    getByConfirmToken(token) {
        return http.get(`/profiles/by-conftoken/${token}`);
    }

    resendConfirmMail(id, role, schoolId) {
        let data = {
            role: role,
            schoolId: schoolId,
        }
        return http.put(`/profiles/resend-confirm/${id}`, data);
    }

    getAll() {
        return http.get("/profiles");
    }

    addRole(id, role, schoolId, confirmed) {
        let data = {
            role: role,
            schoolId: schoolId,
        }
        if (typeof confirmed !== undefined) data.confirmed = confirmed;
        return http.put(`/profiles/add-role/${id}`, data);
    }

    confirmProfile(token) {
        return http.get(`/profiles/confirm-profile/${token}`);
    }

    confirmProfileSchool(token) {
        return http.get(`/profiles/confirm-profile-school/${token}`);
    }

    removeRole(id, role) {
        let data = {
            role: role
        }
        return http.put(`/profiles/remove-role/${id}`, data);
    }

    leaveSchool(id, schoolId) {
        let data = {
            schoolId: schoolId
        }
        return http.put(`/profiles/leave-school/${id}`, data);
    }

    findByName(name) {
        return http.get(`/profiles?name=${name}`);
    }

    delete(profileId, userId, email) {
        return http.delete(`/profiles/${profileId}/${userId}/${email}`);
    }
}

export default new ProfileDataService();
