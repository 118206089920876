import http from "../../helpers/http-common";

class ProductLicenseDataService {
    getAll() {
        return http.get("/product_licenses");
    }

    getAllByProfile(id) {
        return http.get(`/product_licenses/profile/${id}`);
    }

    get(id) {
        return http.get(`/product_licenses/${id}`);
    }

    getByCode(code) {
        return http.get(`/product_licenses/by_code/${code}`);
    }

    hasActiveLicenseByProfile(edulabProductId) {
        return http.get(`/product_licenses/has_active_license/${edulabProductId}`);
    }

    getSchoolIdByCode(code) {
        return http.get(`/product_licenses/school_id_by_code/${code}`);
    }

    downloadPdf(id) {
        return http.get(`/product_licenses/download_pdf/${id}`, {responseType: 'blob'});
    }

    create(data) {
        return http.post("/product_licenses", data);
    }

    generate(data) {
        return http.post("/product_licenses/generate", data, {responseType: 'blob'});
    }

    isActivable(id) {
        return http.get(`/product_licenses/is_activable/${id}`);
    }

    activate(id, data) {
        return http.put(`/product_licenses/activate/${id}`, data);
    }

    activateWithoutProfile(id) {
        return http.put(`/product_licenses/activate_without_profile/${id}`);
    }

    extend(id, days) {
        return http.put(`/product_licenses/extend/${id}`, {days: days});
    }

    update(id, data) {
        return http.put(`/product_licenses/${id}`, data);
    }

    delete(id) {
        return http.delete(`/product_licenses/${id}`);
    }

    deleteAll() {
        return http.delete('/product_licenses');
    }

    deleteMany(ids) {
        return http.post('/product_licenses/bulk_delete', ids);
    }

    findByFilter(filter) {
        let query = '';
        for (const property in filter) {
            if (filter[property]) {
                if (property == 'range') query += `start=${filter[property][0]}&end=${filter[property][1]}&`
                else query += `${property}=${filter[property]}&`
            }
        }
        return http.get(`/product_licenses?${query.slice(0, -1)}`);
    }

    download(filter) {
        let query = '';
        for (const property in filter) {
            if (filter[property]) {
                if (property == 'range') query += `start=${filter[property][0]}&end=${filter[property][1]}&`
                else query += `${property}=${filter[property]}&`
            }
        }
        return http.get(`/product_licenses/download_csv?${query.slice(0, -1)}`, {responseType: "blob"});
    }
}

export default new ProductLicenseDataService();
