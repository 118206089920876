import http from "../../helpers/http-common";

class SchoolDataService {
    getAll() {
        return http.get("/schools");
    }

    getAllWithOwners() {
        return http.get("/schools?owners=1");
    }

    get(id) {
        return http.get(`/schools/${id}`);
    }

    getInfo(id) {
        return http.get(`/schools/info/${id}`);
    }

    create(data) {
        return http.post("/schools", data);
    }

    update(id, data) {
        return http.put(`/schools/${id}`, data);
    }

    updatePrograms(id, data) {
        return http.put(`/schools/programs/${id}`, data);
    }

    delete(id) {
        return http.delete(`/schools/${id}`);
    }

    deleteAll() {
        return http.delete('/schools');
    }

    findByName(name) {
        return http.get(`/schools?name=${name}&owners=1`);
    }
}

export default new SchoolDataService();
