<template>
    <div>

        <div class="row section-header" >
            <div class="col-md-7 section-title">
                <h1>Il tuo profilo</h1>
            </div>

            <div class="col-md-1">

            </div>
            <div class="col-md-3 text-right" style="">

            </div>
        </div>
        <br>

        <div class="content">
            <div class="index">
                <div class="card" v-if="currentUser">
                    <h3>
                        {{ this.currentUser.profile.firstname + ' ' + this.currentUser.profile.lastname }}
                    </h3>
                    <dl>
                        <dt>Email</dt>
                        <dd>{{ this.currentUser.email }}</dd>
                    </dl>

                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-link" @click="openChangePassword" ><b-icon icon="pencil"/> Modifica password</button>
                        </div>
                        <div class="col-md-6">
                            <button class="btn btn-primary btn-block" @click="$router.push('logout')">Esci</button>
                        </div>
                    </div>
                </div>
                <br>
                <div class="card">
                    <h4>Privacy</h4>
                    <ul class="list-links">
                        <li><a :href="privacyUrl" target="_blank"><b>Leggi l'informativa sulla privacy <b-icon-box-arrow-up-right/></b></a></li>
                    </ul>
                </div>
                <br>
                <div class="card">
                    <h4>Condizioni di utilizzo accettate</h4>
                    <p class="small">Clicca sul nome per leggere le condizioni della licenza.</p>
                    <ul class="list-links">
                        <li
                            v-for="item in activeLicenses"
                            :key="item.id">
                            <a href="#" v-on:click.prevent="showTermsOfUse(item)"><b>{{ item.name }}</b></a>
                        </li>
                    </ul>
                </div>
                <br>
                <div class="card">
                    <h4>Scuole a cui sei iscritto</h4>
                    <p class="small">Clicca sul nome p.</p>
                    <ul class="list-links">
                        <li
                            v-for="item in schools"
                            :key="item.id">
                            <a href="#" v-on:click.prevent="showSchool(item)"><b>{{ item.name }}</b></a>
                        </li>
                    </ul>
                </div>
                <br>
                <br>
                <div class="">
                    <button class="btn btn-link text-muted btn-sm" @click="openDelete" ><b-icon icon="trash"/> Elimina definitivamente il tuo profilo da EduGest</button>
                </div>

            </div>

            <div class="editor" :class="{ 'opened': (editorOpened) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="changePassword">
                    <change-password v-if="currentUser" :user-id="currentUser.id" :key="currentUser.id"
                                 v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-if="deleteProfile">
                    <profile-delete v-if="currentUser" :user="currentUser" :key="currentUser.id"
                                    v-on:closed-editor="closeEditor()"
                                    v-on:profile-deleted="deleted()"/>
                </div>
                <div v-if="currentLicense" class="card">
                    <h3>{{ currentLicense.name }}</h3>
                    <div v-html="currentLicense.termsOfUse" ></div>
                </div>
                <div v-if="currentSchool" class="card">
                    <h3>{{ currentSchool.businessName || currentSchool.name }}</h3>
                    <dl class="">
                        <dt class="">Nome</dt>
                        <dd class="">{{ currentSchool.name }}</dd>

                        <dt class="">Ragione Sociale</dt>
                        <dd class="">{{ currentSchool.businessName }}</dd>

                        <dt class="">Indirizzo</dt>
                        <dd class="">{{ currentSchool.address }}</dd>

                        <dt class="">Partita IVA</dt>
                        <dd class="">{{ currentSchool.vat }}</dd>

                        <dt class="">Email</dt>
                        <dd class="">{{ currentSchool.email }}</dd>

                        <dt class="">Pec</dt>
                        <dd class="">{{ currentSchool.pec }}</dd>

                        <dt class="">Telefono</dt>
                        <dd class="">{{ currentSchool.telephone }}</dd>
                    </dl>
                    <b-button @click="leaveSchool" variant="danger"><b-icon-trash/> Lascia questa scuola</b-button>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import AuthDataService from "@/components/auth/AuthDataService";
import ChangePassword from "@/components/profile/ChangePassword";
import ProfileDelete from "@/components/profile/ProfileDelete";
import router from "@/router";
import ProductLicenseDataService from "@/components/product_license/ProductLicenseDataService";
import ProductLicenseTemplateDataService from "@/components/product_license_template/ProductLicenseTemplateDataService";
import SchoolDataService from "@/components/school/SchoolDataService";
import ProfileDataService from "@/components/profile/ProfileDataService";

export default {
    name: "ProfileView",
    components: {ProfileDelete, ChangePassword},
    data() {
        return {
            profileId: -1,
            currentUser: null,
            editorOpened: false,
            changePassword: false,
            deleteProfile: false,
            activeLicenses: [],
            currentLicense: null,
            schools: [],
            currentSchool: null,
            privacyUrl: 'https://www.toccafondimultimedia.com/privacy/'
        }
    },
    methods: {
        showTermsOfUse(item) {
            this.resetCurrents();
            ProductLicenseTemplateDataService.get(item.templateId)
                .then(response => {
                    this.currentLicense = item;
                    this.currentLicense.termsOfUse = response.data.termsOfUse;
                    this.editorOpened = true;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        showSchool(item) {
            this.resetCurrents();
            SchoolDataService.get(item.id)
                .then(response => {
                    this.currentSchool = response.data;
                    this.editorOpened = true;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        leaveSchool() {
            if (!confirm('Sei sicuro di voler abbandonare questa scuola?')) return;
            alert('Verrà eseguito un logout automatico e dovrai nuovamente rientrare dal login.');

            ProfileDataService.leaveSchool(this.profileId, this.currentSchool.id)
                .then(response => {
                    if (response.status == 200) {
                        this.errorMsg = '';
                        this.deleted();
                    } else {
                        this.errorMsg = 'errore';
                        this.item.oldPassword = null;
                    }
                })
                .catch(e => {
                    this.errorMsg = e.response.data.message;
                    console.log(e);
                });
        },
        getUser() {
            AuthDataService.me(this.profileId)
                .then(response => {
                    this.currentUser = response.data;
                    this.schools = this.currentUser.profile.schools
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },
        openChangePassword() {
            this.resetCurrents();
            this.changePassword = true;
            this.editorOpened = true;
        },
        openDelete() {
            this.resetCurrents();
            this.deleteProfile = true;
            this.editorOpened = true;
        },
        closeEditor() {
            this.editorOpened = false;
            this.resetCurrents();
        },
        resetCurrents() {
            this.changePassword = false;
            this.deleteProfile = false;
            this.currentLicense = null;
            this.currentSchool = null;
        },
        deleted() {
            // logout
            localStorage.removeItem('authUser');
            this.$store.state.isLoggedIn = false;
            this.$store.state.user = null;
            this.$store.state.token = null;
            router.push("/login").catch(e => {});
        }
    },
    mounted() {
        this.profileId = this.$store.state.user.profileId;
        ProductLicenseDataService.getAllByProfile(this.profileId)
            .then(response => {
                this.activeLicenses = response.data;
            })
            .catch(e => {
                console.log(e);
            });
        this.getUser();
    }
}
</script>

<style scoped>

</style>