<template>
    <div class="card">
        <br>
        <h3>Cancellazione definitiva del profilo</h3>
        <p>ATTENZIONE: con questa procedura verranno eliminati tutti i tuoi dati, tutte le tue iscrizioni alle scuole e tutti i tuoi prodotti acquistati.</p>
        <p>Di seguito un breve riepilogo:</p>


        <b-form>
            <p><b>Sei attualmente iscritto alle seguenti scuole:</b></p>
            <b-form-checkbox
                v-for="school in schoolCheckboxes"
                :key="school.id"
                :id="school.name"
                v-model="school.checked">
                {{ school.name }}
            </b-form-checkbox>
            <br>
            <p><b>Hai i seguenti prodotti attivi:</b></p>
            <b-form-checkbox
                v-for="license in activeLicenseCheckboxes"
                :key="license.id"
                :id="license.name"
                v-model="license.checked">
                {{ license.name }}
            </b-form-checkbox>

            <hr>
            <b-form-checkbox
                id="accept"
                v-model="accept"
                name="accept">
                Dichiaro di aver letto e accettato l'informativa e di accettare la cancellazione definitiva di tutti i miei dati da EduGest.
            </b-form-checkbox>
        </b-form>
        <br>
        <br>

        <b-button variant="danger"  @click="deleteProfile" :disabled="this.$store.state.loading">
            Cancella definitivamente il profilo
        </b-button>

        <br>
        <div>
            <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
        </div>


    </div>
</template>

<script>
import ProfileDataService from "@/components/profile/ProfileDataService";
import ProductLicenseDataService from "@/components/product_license/ProductLicenseDataService";

export default {
    name: "profile-delete",
    data() {
        return {
            accept: null,
            schoolCheckboxes: [],
            activeLicenseCheckboxes: [],
            schools: [],
            activeLicenses: [],
            message: '',
            errorMsg: '',
        };
    },
    props: ["user"],
    methods: {
        deleteProfile() {
            this.errorMsg = '';
            if (this.schoolCheckboxes.findIndex(s => !s.checked) > -1) {
                this.errorMsg += "Devi spuntare i checkbox delle scuole da cui ti stai cancellando."
                return;
            }
            if (this.activeLicenseCheckboxes.findIndex(l => !l.checked) > -1) {
                this.errorMsg += "Devi spuntare tutti i checkbox di prodotti che stai cancellando."
                return;
            }
            if (!this.accept) {
                this.errorMsg += "Devi spuntare il checkbox di approvazione."
                return;
            }
            // confirm dialog
            if (!confirm('Sei sicuro di voler cancellare definitivamente il tuo profilo?')) return;

            ProfileDataService.delete(this.user.profile.id, this.user.id, this.user.email)
                .then(response => {
                    if (response.status == 200) {
                        this.errorMsg = '';
                        this.$emit('profile-deleted');
                    } else {
                        this.errorMsg = 'errore';
                        this.item.oldPassword = null;
                    }
                })
                .catch(e => {
                    this.errorMsg = e.response.data.message;
                    console.log(e);
                });

        },
        initCheckboxes() {
            // schools
            for (let i = 0; i < this.schools.length; i++) {
                this.schoolCheckboxes.push({
                    name: this.schools[i].name,
                    checked: false
                });
            }
            // licenses
            for (let i = 0; i < this.activeLicenses.length; i++) {
                this.activeLicenseCheckboxes.push({
                    name: this.activeLicenses[i].name,
                    checked: false
                });
            }
        },
        closeEditor() {
            this.$emit('closed-editor');
        },
    },
    mounted() {
        this.schools = this.user.profile.schools;
        ProductLicenseDataService.getAllByProfile(this.user.profile.id)
            .then(response => {
                this.activeLicenses = response.data;
                this.initCheckboxes();
            })
            .catch(e => {
                console.log(e);
            });
    }
};
</script>

<style>

</style>
